<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
const Matpel = require('./../../../api/matpel');
import Swal from "sweetalert2";
/**
 * Product-checkout Component
 */
export default {
   page: {
    title: "Mata Pelajaran",
    meta: [{ name: "description", content: 'Mata Pelajaran on Tryout Apps' }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Mata Pelajaran",
      items: [
        {
          text: "Mata Pelajaran",
          active: true
        }
      ],
      Matpel : new Matpel(),
      lists : [],
      dataMatpel : {
          id : null,
          jenjang : null,
          name : ""
      },
      states : {
          error : false,
          loading : false,
          isCreate : false,
          message : "This value is required.",
          selectedIndex : 0,
      },
    };
  },
  mounted(){
      this.getList()
  },
  methods : {
      getList(){
          this.Matpel.getList()
          .then(res=>{
              this.lists = res;
          })
          .catch((e)=>console.log(e))
      },
      create(list){
          this.dataMatpel.jenjang = list
          this.dataMatpel.name = ""
          this.states.isCreate = true;
      },
      edit(list,matpel){
          this.dataMatpel.jenjang = list
          this.dataMatpel.name = matpel.matpel
          this.dataMatpel.id = matpel.id
          this.states.isCreate = true;
      },
      reset(){
          this.dataMatpel.jenjang = null
          this.dataMatpel.name = ""
          this.dataMatpel.id = null
          this.states.isCreate = false;
          this.states.error = false;
      },
      save(){
          this.Matpel.save(this.dataMatpel,this.states)
          .then((state)=>{
              this.states = state
              this.reset()
              this.getList()
          })
          .catch(e=>{
              this.states=e
          })
      },
    hapus(id){
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger ml-2"
            },
            buttonsStyling: false
        });
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Yes, delete it!"
        }).then(result => {
            if (result.value) {
                this.Matpel.hapus({id:id})
                .then((res)=>{
                    this.getList()
                    if(res.success){
                        Swal.fire("Deleted!", "Your file has been deleted.", "success");
                    }else{
                        swalWithBootstrapButtons.fire(
                        "Cancelled",
                        res.data,
                        "error"
                        );
                    }
                })
                .catch((err)=>{
                        swalWithBootstrapButtons.fire(
                        "Cancelled",
                        err,
                        "error"
                        );
                })
            }
        });
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="checkout-tabs">
      <b-tabs pills vertical nav-class="p-0" nav-wrapper-class="col-lg-2 w-100" v-model="states.selectedIndex">
        <b-tab :active="index==states.selectedIndex" v-for="(list,index) in lists" :key="index">
          <template v-slot:title>
            <i class="bx bx-building-house d-block check-nav-icon mt-4 mb-2"></i>
            <p class="font-weight-bold mb-4">{{list.jenjang}}</p>
          </template>
          <b-card-text>
            <div class="card shadow-none border mb-0" v-if="!states.isCreate">
              <div class="card-body">
                <div class="d-flex justify-content-between">
                    <h4 class="card-title mb-4">Mata Pelajaran pada Jenjang Pendidikan ({{list.jenjang}})</h4>
                    <button class="btn btn-success ml-2 mb-3 btn-sm" @click="create(list)"><i class="bx bx-plus-circle"></i> Add New</button>
                </div>
                <div class="table-responsive">
                  <table class="table table-centered mb-0 table-nowrap">
                    <thead class="thead-light">
                      <tr>
                        <th scope="col">Nama Mata Pelajaran</th>
                        <th scope="col">Total Paket</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="matpel in list.matpels" :key="matpel.id">
                        <th scope="row">
                          <p class="mb-0">{{matpel.matpel}}</p>
                        </th>
                        <td>
                          <p class="text-muted mb-0">{{matpel.totalPaket}}</p>
                        </td>
                        <td>
                            <button class="btn btn-warning btn-sm mx-1" @click="edit(list,matpel)"   v-b-tooltip.hover title="Edit"><i class="bx bx-edit"></i></button>
                            <button class="btn btn-danger btn-sm  mx-1" @click="hapus(matpel.id)"  v-b-tooltip.hover title="Delete" ><i class="bx bx-trash"></i></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- section create -->
                <div id="create" class="card shadow-none border mb-0" v-else>
                    <div class="card-body">
                        <div class="from-group">
                            <label class="control-label">Jenjang</label>
                            <input type="text" class="form-control" disabled v-model="dataMatpel.jenjang.jenjang">
                        </div>
                        <div class="form-group mt-4">
                            <label class="control-label">Nama Mata Pelajaran <span class="text-danger">*</span></label>
                            <input type="text" :class="{ 'is-invalid': states.error,'form-control':true }" v-model="dataMatpel.name">
                                <div class="invalid-feedback">
                                    <span>This value is required.</span>
                                </div>
                        </div>
                        <div class="mt-4">
                            <button class="btn btn-danger btn-sm mx-1" @click="reset()"  v-b-tooltip.hover title="Cancel"><i class="bx bx-x-circle"></i> Cancel</button>
                            <button class="btn btn-info btn-sm  mx-1"  @click="save()" v-b-tooltip.hover title="Save" ><i class="bx bxs-cloud-upload"></i> Save</button>
                        </div>
                    </div>
                </div>
            <!-- section create end -->
          </b-card-text>
        </b-tab>
      </b-tabs>
    </div>
  </Layout>
</template>
